import React from "react"
import { Link, PageProps } from "gatsby"

import Layout from "../hoc/Layout"
import Image from "../components/image"
import SEO from "../components/seo"
import RaisedPanel from "../hoc/card/Card"

const ContactPage: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="Contact" />
  </Layout>
)

export default ContactPage
